import Navbar from "../component/Navbar";
import Banner from "../component/Banner";
import ProductCard from "../component/ProductView";
import Curbside from "../component/Curbside";
import Footer from "../component/Footer";
import { useEffect, useState } from "react";
import { getPosts } from "../Services/GetUser.service";
import { Product } from "../Model/prodduct";
import { Link, useNavigate } from "react-router-dom";
import CollaborativePartner from "../component/Collaborate";
import HeroSection from "../component/HowManYeaar";
import CommunityCommitment from "../component/NationalBuilding";

function Home() {
    const navigate = useNavigate()


  // enter path to image when ever you want to change the banner image
  const images = ["/image/u2.png"];
  const images2 = ["/image/u2.png"];
  const handleBuyNow = (item:Product) => {
  navigate(`/product/brands/checkout/${item.id}`)
  };
  return (
   <>
   
    <div>
      <Navbar />
      <Banner images={images} images2={images2} />
      <CollaborativePartner/>
      <HeroSection/>
      <CommunityCommitment/>
      <Footer />
    </div>

   </>
  );
}

export default Home;
