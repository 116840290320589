import React, { useState } from 'react';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { database } from '../firebase'; // Correct import
import { getRandomString } from '../Services/GetRandomNumber';
import { getusers } from '../Services/GetUser.service';

const Register: React.FC = () => {
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const handleLogin = () => {
    window.location.href = '/auth/login';
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      !firstname ||
      !lastname ||
      !email ||
      !number ||
      !password ||
      !confirmPassword
    ) {
      setMessage('Incomplete information');
      return;
    }

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    getusers(email, (res: any[]) => {
      const existingUser = res.find((user) => user.email === email);
      if (existingUser) {
        window.alert('User already exists');
        return;
      }
    });

    try {
      setLoading(true);

      const user = {
        id: getRandomString(35, '1234567890qwertyuiopasdfhjklzxcvbnmQWERTYUIOPASDFHJKLZXCVBNM'),
        firstname,
        lastname,
        email,
        phone: number,
        active: true,
        permission: 'admin',
        wallet: 0,
        created: serverTimestamp(),
      };

      await setDoc(doc(database, 'user', user.id), user);
      setMessage('Registration successful. Redirecting to login...');
      setTimeout(() => {
        window.location.href = '/auth/login';
      }, 2000);
    } catch (error) {
      setMessage((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="min-h-screen bg-cover bg-center flex items-center justify-center py-8"
        style={{
          backgroundImage: 'url("/image/u16.jpg")',
        }}
      >
        <div className="absolute h-[110vh] inset-0 bg-black opacity-60"></div> {/* Black overlay */}
        <div className="relative z-10 bg-white p-8 rounded-lg shadow-lg w-full max-w-md"> {/* White container */}
          <h2 className="text-2xl text-white font-bold bg-black text-center p-2 rounded">Register</h2>
          {message && (
            <div className="mt-4 p-4 bg-blue-100 text-blue-800 rounded">
              {message}
            </div>
          )}

          <form onSubmit={handleRegister} className="mt-4">
            <div className="mb-4">
              <label className="block text-gray-700">First Name</label>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Enter first name"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Last Name</label>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Enter last name"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Email Address</label>
              <input
                type="email"
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Phone Number</label>
              <input
                type="tel"
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="+123 - 999 - 999"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Password</label>
              <input
                type="password"
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Confirm Password</label>
              <input
                type="password"
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            <div className="text-sm text-gray-600 mb-4">
              <span>Already have an account?</span>
              <span
                className="text-blue-600 cursor-pointer ml-1"
                onClick={handleLogin}
              >
                Login
              </span>
            </div>

            <button
              type="submit"
              className={`w-full p-2 bg-black text-white rounded ${
                loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-orange-900'
              }`}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Register'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
