import React from 'react';

const HeroSection: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-center text-gray-800 md:text-6xl">
        WE'VE DONE ONE THING FOR NEARLY
        <br />
        22 YEARS: <span className="block text-6xl md:text-8xl">BUILD</span>
      </h1>
      <p className="mt-4 text-lg text-center text-gray-600 md:text-xl">
        We assemble high-performing teams ready to solve any challenge and achieve your goals.
      </p>
    </div>
  );
}

export default HeroSection;
