import React, { useState, useEffect } from "react";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { database, storage } from "../firebase"; // Ensure correct path
import { serverTimestamp } from "firebase/firestore"; // Import serverTimestamp

const Dashboard: React.FC = () => {
  const [images, setImages] = useState<{ name: string; url: string }[]>([]);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      const imagesCollection = collection(database, "images");
      const snapshot = await getDocs(imagesCollection);
      const imageArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        name:doc.data().name as string,
        url: doc.data().url as string,
      }));
      setImages(imageArray);
    };

    fetchImages();
  }, []);

  const handleImageUpload = async () => {
    setloading(true);
    const uploadedImages = await Promise.all(
      newImages.map(async (image) => {
        const imageRef = storageRef(storage, `images/${image.name}`);
        await uploadBytes(imageRef, image);
        const imageUrl = await getDownloadURL(imageRef);

        // Generate a new Firebase ID
        const newDocRef = doc(collection(database, "images"));
        const imageData = {
          name: image.name,
          id: newDocRef.id,
          url: imageUrl,
          createdAt: serverTimestamp(), // Use Firebase server timestamp
        };

        await setDoc(newDocRef, imageData);
        return imageData;
      })
    );

    setImages((prev) => [...prev, ...uploadedImages]);
    setNewImages([]);
    setloading(false);
    setIsModalOpen(false);
  };

  const handleImageDelete = async (imageName:any) => {
    console.log(imageName)
    setloading(true);
    const imageRef = storageRef(storage, `images/${imageName}`);
    await deleteObject(imageRef);
    await deleteDoc(doc(database, "images", imageName));
    setImages((prev) => prev.filter((image) => image.name !== imageName));
    setloading(false);
  };

  return (
    <div className="bg-white mb-3 h-screen">
      <div className="text-center text-[2rem] p-5 font-extrabold text-black border-b-2 mb-10">
        Control Panel
      </div>

      <div className="min-h-screen px-5">
        <div className="text-center font-extrabold text-[1.5rem]">
          IMAGE GALLERY
        </div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-blue-500 text-white p-2 rounded"
          disabled={loading}
        >
          {loading ? <div className="animate-spin">---</div> : "Show App Image"}
        </button>

        <div className="grid gap-5 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mt-5">
          {images.map((image, id) => (
            <div key={id} className="relative">
              <img src={image.url} alt="image" className="h-[50vh] w-[100%]" />
              <button
                onClick={() => handleImageDelete(image.name)}
                className="absolute top-2 right-6 bg-red-500 text-white p-1 rounded"
                disabled={loading}
              >
                {loading ? <div className="animate-spin">--</div> : "Delete"}
              </button>
            </div>
          ))}
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-5 rounded-lg shadow-lg">
              <div className="text-center font-bold text-lg mb-4">
                Upload Images
              </div>
              <input
                type="file"
                multiple
                onChange={(e) => setNewImages(Array.from(e.target.files || []))}
                className="mb-4 border border-black p-3 rounded-lg"
              />
              <br />
              <button
                onClick={handleImageUpload}
                className="bg-blue-500 text-white p-2 rounded mr-2"
              >
                {loading ? <div className="animate-spin">---</div> : "Upload"}
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                disabled={loading}
                className="bg-gray-500 text-white p-2 rounded"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
