// src/components/LuxuryBagsPro.tsx
import React, { useEffect, useState } from "react";
import "../App.css";
import {collection, getDocs } from 'firebase/firestore';
import { database } from '../firebase'; // Ensure correct path
import { Helmet } from "react-helmet-async";
const LuxuryBagsPro: React.FC = () => {
  const [images, setImages] = useState<{ name: string; url: string }[]>([]);

  useEffect(() => {
    const fetchImages = async () => {
      const imagesCollection = collection(database, "images");
      const snapshot = await getDocs(imagesCollection);
      const imageArray = snapshot.docs.map(doc => ({
        name: doc.id,
        url: doc.data().url as string,
      }));
      setImages(imageArray);
    };

    fetchImages();
  }, []);
  

  return (
    <>
      <Helmet>
        <title>EMCHRIS ENGINEERING NIG LTD</title>
        <meta name="description" content="Explore our luxury construction projects, including the exclusive LuxuryBagsPro collection, provided by EMCHRIS ENGINEERING NIG LTD." />
        <meta name="keywords" content="Construction, Engineering, EMCHRIS ENGINEERING NIG LTD" />
      </Helmet>
      <div className="min-h-screen px-5">
        <div className=" text-center font-extrabold text-[1.5rem] py-10">OUR PROJECT</div>
        <div className="grid gap-5 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
          {images.map((image, id) => {
            return (
              <>
                <div className="" key={id}>
                  <img src={image.url} alt="image" className="h-[50vh] w-[100%]" />
                </div>
              </>
            );
          })}
        </div>

        <div className=" flex justify-center items-center gap-10 mt-10">
          <div className="">
            <h2 className="text-lg font-semibold mb-2 text-gray-800">
              Why Choose Us?
            </h2>
            <ul className="list-disc list-inside text-gray-700">
              <li>
                <span className="font-semibold">Expertise:</span> With years of
                experience in the construction industry, we provide high-quality
                building solutions that meet the highest standards.
              </li>
              <li className="mt-2">
                <span className="font-semibold">Exposure:</span>We offer a wide
                range of construction services and have built strong
                partnerships with leading suppliers and industry experts.
              </li>
              <li className="mt-2">
                <span className="font-semibold">Transparency:</span>Stay
                informed with regular updates throughout your construction
                project, from planning to completion.
              </li>
              <li className="mt-2">
                <span className="font-semibold">Security:</span> We ensure the
                safety and integrity of your construction projects with
                comprehensive insurance coverage and stringent security
                measures.
              </li>
            </ul>
          </div>
        </div>
        <div className="max-w-4xl mx-auto p-8">
          <h2 className="text-2xl font-bold text-blue-900 text-center mb-4">
            HOW IT WORKS
          </h2>
          <ol className="list-decimal list-inside mb-8">
            <li className="mb-2">
              <span className="font-bold">SEvaluation:</span> Provide details
              and plans online or visit us for a consultation.
            </li>
            <li className="mb-2">
              <span className="font-bold">Agreement:</span>Sign our construction
              terms, including pricing and project timelines.
            </li>
            <li className="mb-2">
              <span className="font-bold">Build:</span> We start construction,
              keeping you updated at every stage of the process.
            </li>
          </ol>
          <p className="mb-4">
            Get Started Today! <br />
            Join others who trust us with their building projects. Start by
            filling out our online form or visiting our office.
          </p>
          <p className="mb-4">
            Let us help you bring your construction vision to life while you
            enjoy the convenience and benefits of working with professionals.
          </p>
          <p className="mb-8">
            Discover a seamless way to manage your construction projects with
            our expert team. Unlock the potential of your property effortlessly.
          </p>
          <p className="text-center">
            For questions, visit our "Contact" page to reach out today.
          </p>
        </div>
      </div>
    </>
  );
};

export default LuxuryBagsPro;
