import React from 'react';

const CollaborativePartner: React.FC = () => {
  return (
    <div className="relative flex items-center justify-center bg-gray-800 text-white md:h-screen">
      <img 
        src="/image/u1.jpg" 
        alt="Collaborative Workers" 
        className="absolute inset-0 w-full h-full object-cover opacity-60" 
      />
      <div className="relative z-10 flex flex-col items-start max-w-xl  p-2 md:p-8 bg-black bg-opacity-50">
        <h1 className="text-4xl font-bold mb-4">
          A TRUSTED, COLLABORATIVE PARTNER <br /> 
          <span className="text-yellow-400">FOCUSED ON YOUR GOALS</span>
        </h1>
        <p className="text-lg mb-6">
          From our industry-leading approaches to safety and quality, to our passion for progressive delivery, innovation and technology, we deliver certainty by focusing on what matters to you.
        </p>
        <button className="bg-red-600 text-white px-6 py-3 rounded hover:bg-red-700">
          LEARN MORE
        </button>
      </div>
    </div>
  );
}

export default CollaborativePartner;
