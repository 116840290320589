import React from 'react';
import { Link } from 'react-router-dom';

const CommunityCommitment: React.FC = () => {
  return (
    <>
   
    <div className="flex flex-col md:flex-row bg-white">
      <div className="md:w-1/2">
        <img 
          src="/image/u11.jpg" 
          alt="Community engagement session"
          className="w-full h-[80vh]"
        />
      </div>
      <div className="md:w-1/2 p-8 flex flex-col justify-center">
        <h2 className="text-3xl font-bold mb-2">NATIONAL BUILDERS</h2>
        <h3 className="text-2xl font-semibold mb-4">DEEPLY COMMITTED TO OUR COMMUNITIES</h3>
        <p className="mb-6">
          We leverage national resources and talent to build across the
          country and positively impact each community we touch.
        </p>

      </div>
    </div>

    <div className="relative w-full h-screen">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img 
          src="/image/u1.jpg" 
          alt="Construction workers at port" 
          className="w-full h-full object-cover"
        />
      </div>
      
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-40"></div>
      
      {/* Content */}
      <div className="absolute inset-0 flex items-center justify-center p-8">
        {/* Left Content */}
        <div className="text-white">
          <h1 className="text-5xl font-bold mb-4">LET'S BUILD<br />TOGETHER</h1>
          <Link to="/gallery">
          <button className="bg-red-600 text-white px-6 py-3 rounded-md font-semibold">
            VIEW GALLERY
          </button>
          </Link>
        </div>
    
      </div>
    </div>
    </>
  );
};

export default CommunityCommitment;