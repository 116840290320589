import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-white py-10 px-6">
 
      <div className="text-center mt-4">
        <p>Copyright &copy;EMCHRIS ENGINEERING</p>
      </div>
    </footer>
  );
}

export default Footer;
